@tailwind base;

@layer base {
    h2 {
        @apply text-hero-mobile sm:text-hero;
    }
}

@tailwind components;
@tailwind utilities;

div.img-container {
    @apply px-6 snap-center flex flex-col items-center justify-between;
}

div.img-container img {
    @apply mt-5 w-auto max-w-fit h-[75vh] sm:h-auto sm:max-w-full sm:w-auto;
}

div.img-container span {
    @apply whitespace-nowrap sm:whitespace-normal;
}

div.point {
    @apply w-1 h-1 bg-white rounded-full;
}

div.point.active {
    @apply w-2 h-2 bg-primary;
}
